/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import HolisticSkillCardGrid from "../components/HolisticSkillCardGrid";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

import { LearningBetter } from "../assets/images/brailleheaders";

const LearningThroughPlay = ({
  pageContext: {
    learningThroughPlayPage: { title, introduction, body, seo },
    holisticSkills,
  },
}) => (
  <Layout
    title={seo.title || "Learning through play"}
    description={seo.description}
    brailleTextImage={LearningBetter}
  >
    <div
      sx={{
        maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
        marginBottom: "100px",
      }}
    >
      <Themed.h1>{title}</Themed.h1>
      <RichText render={introduction} />
    </div>
    <HolisticSkillCardGrid holisticSkills={holisticSkills} />
    <div
      sx={{
        maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
        marginTop: "100px",
      }}
    >
      <RichText render={body} />
    </div>
  </Layout>
);

export default LearningThroughPlay;
